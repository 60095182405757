import React from "react";
import Layout from "../components/layout"
import "./index.scss"

export default () => (
  <Layout pageId={"/shou-ji-kai-men"}>
    <div className="container-fluid bg-wave-top">
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <h1 style={{color:'white', fontWeight:'bold', fontStyle:'italic'}}>
              通行与访客
            </h1>
            <p style={{color: 'white'}}>
              随着智能手机的全面普及和人工智能技术在人脸识别领域的成熟应用，传统的<span className="text-emphasize">钥匙和感应卡正在被全面淘汰</span>。基于人脸识别和智能手机的身份识别系统在<span className="text-emphasize">门禁、道闸、访客机、梯控</span>等场景中正在快速取代传统的通行方式。<span className="text-emphasize">人脸识别开门和手机开门</span>正在成为当下最潮的科技。
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/2.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            人脸识别通行
          </h1>
          <p>
            客户信息登记、意向客户管理、商机管理、线索管理
            {/*无论是几个人运营的孵化器还是上百人管理运营的大型企业园区，企业内部的信息化管理是基础。你可以选择互联网企业协同工具，比如钉钉、企业微信、飞书，也可以选择更为传统、功能也更强大的传统OA软件，比如泛微、致远等。我们可以提供与这些协同平台的对接集成。*/}
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/S8.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              手机开门
            </h1>
            <p>
              企业入驻、续租、退租等全流程电子化管理
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/6.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            空间运营
          </h1>
          <p>
            活动管理、贴吧论坛、资讯发布，以及会议室预定等增强空间与企业的互动。
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/chahua/2_1.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              品质服务
            </h1>
            <p>
              面向入驻企业与人员的服务流程管理。可以灵活配置信息表单、服务流程、处理审批流程。服务处理进展、处理率等一目了然。
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/1.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            物业管理
          </h1>
          <p>
            涵盖报修、通行、访客、巡更巡检等物业基础服务管理
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/community/2.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </Layout>
);
